import React, { useEffect, useState } from "react"
import Title from "../components/shared-title"
import useStores from "../hooks/use-stores"
import { observer } from "mobx-react";
import { Link, navigate } from "gatsby";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import VmButton from "../components/shared-button";
import { isBrowser, Language, NavListByCategory, ProdEnvCheck } from "../constants/options";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { t } from "i18next";
import { ITEM_PERFECT_INLINED } from "../constants/style";

const VerifyEmailPage = observer(({ location }) => {
  const { userStore, rootStore } = useStores();
  const [email, setEmail] = useState<string>("");
  const [loadingType, setLoadingType] = useState<"Verify" | "Send">("Verify");
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  const [enableResend, setEnableResend] = useState<boolean>(location.state && location.state.resend ? true : false);

  useEffect(() => {
    if (isBrowser && !localStorage.USER_TOKEN && !enableResend) {
      rootStore.notify("Invalid user token, please try resending verification email and try again", "warning");
    } else {
      // if not resending the email
      if (!location.state || !location.state.resend) {
        userStore.verifyEmail()
          .then(() => {
            rootStore.notify("Email validated! Please sign in now", "success");
            navigate("/");
          }).catch(() => {
            rootStore.notify("Invalid user token, please try resending verification email and try again", "warning");
            delete localStorage.USER_TOKEN;
            setEnableResend(true);
          });
      }
    }
  }, []);

  const resendVerifyEmail = () => {
    if (email !== confirmEmail) {
      rootStore.notify("Two emails are not match!", "warning");
      return;
    }
    setLoadingType("Send");
    userStore.resendVerifyEmail(email)
      .then(() => {
        rootStore.notify("Email sent! Please check your email", "success");
      }).catch(() => rootStore.notify("Email sent failed! Please check your internet or contact network admin", "error"));
  }

  return (
    <>
      <Title pageName={t('RESET_PASSWORD')} />
      <div className="top-0 left-0 right-0 bottom-0 h-screen relative bg-theme">
        <button
          className="absolute bg-white rounded-md px-4 py-2 hover:text-theme"
          style={{ top: '2%', left: '1%' }}
          onClick={() => navigate("/")}
        >
          <Box sx={ITEM_PERFECT_INLINED}>
            <ArrowBackIosNewIcon />
            <Typography marginLeft={1}>Go Back</Typography>
          </Box>
        </button>
        <div
          className="absolute top-1/2 left-1/2"
          style={{ transform: 'translate(-50%, -50%)', width: 'fit-content' }}
        >
          <div
            className={`relative border border-white overflow-auto shadow-2xl`}
            style={{ background: "rgba(255, 255, 255, 0.3)" }}
          >
            <div className="bg-white m-4 relative p-4 shadow-lg">
              {isBrowser && enableResend ?
                <>
                  <Typography mb={2} textAlign="center" variant="h6" className="text-theme">Resend Verification Email</Typography>
                  <TextField
                    value={email}
                    label={t('EMAIL')}
                    sx={{ width: "100%" }}
                    variant="outlined"
                    onChange={(value) => setEmail(value.target.value)}
                  />
                  <TextField
                    value={confirmEmail}
                    label={t('CONFIRM_EMAIL')}
                    sx={{ marginY: 2, width: "100%" }}
                    variant="outlined"
                    onChange={(value) => setConfirmEmail(value.target.value)}
                  />
                  <div className="text-center my-2">
                    {userStore.loading ? <div className="py-4"><CircularProgress className="text-theme" /></div>
                      : <VmButton
                        className="bg-theme text-white w-full py-2"
                        style={{ height: 'fit-content' }}
                        disabled={email === "" || confirmEmail === ""}
                        onClick={resendVerifyEmail}
                      >
                        {t('SEND_EMAIL')}
                      </VmButton>
                    }
                  </div>
                </>
                : <Box textAlign={"center"}>
                  <CircularProgress />
                  <Typography variant="body1" mt={1} mx={4}>{loadingType === "Verify" ? "Verifying email..." : "Sending verification email..."}</Typography>
                </Box>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default VerifyEmailPage;
