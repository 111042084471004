// Styles used by native base
export const THEME_COLOR = "white"//"#8e9aae";
export const THEME_BG = "#ebedf0";
export const REVERSE_THEME = "#becadb";
export const THEME_HIGHLIGHT = '#2c3bea';
export const THEME_BUTTON = '#faa000';
export const TD_FIRST = 'p-2 lg:py-2 2xl:py-3 bg-white rounded-l-xl lg:text-sm 2xl:text-md';
export const TD_LAST = 'p-2 lg:py-2 2xl:py-3 bg-white rounded-r-xl lg:text-sm 2xl:text-md';
export const TD_NORMAL = 'p-2 lg:py-2 2xl:py-3 bg-white lg:text-sm 2xl:text-md';
export const TD_FIRST_PENDING_REQUEST = 'p-2 py-3 bg-red-100 rounded-l-xl';
export const TD_NORMAL_PENDING_REQUEST = "p-2 py-3 bg-red-100 ";
export const TD_LAST_PENDING_REQUEST = 'p-2 py-3 bg-red-100 rounded-r-xl';
export const INLINE_SELECT_OPTION = 'w-full text-black text-right px-4 lg:py-2 2xl:py-3 bg-white rounded-md mb-2 lg:text-sm 2xl:text-base hover:bg-highlight hover:text-white';
export const INLINE_SELECTED_OPTION = 'w-full text-right px-4 lg:py-2 2xl:py-3 bg-highlight text-white rounded-md mb-2 lg:text-sm 2xl:text-base hover:bg-highlight hover:text-white';
export const INLINE_SELECT_OPTION_MINI = 'w-full text-black text-right px-4 py-2 bg-white rounded-md mb-2 text-sm hover:bg-highlight hover:text-white';
export const INLINE_SELECTED_OPTION_MINI = 'w-full text-right px-4 py-2 bg-highlight text-white rounded-md mb-2 text-sm hover:bg-highlight hover:text-white';
export const INLINE_SELECTED = 'bg-highlight text-white';
export const SUCCESS_BUBBLE = "p-2 bg-green-500 rounded-l-xl rounded-r-xl mr-2 mt-2 text-white shadow-lg hover:shadow-sm transition-all";
export const TRANSFER_BUBBLE = "p-2 bg-yellow-400 rounded-l-xl rounded-r-xl mr-2 mt-2 text-white shadow-lg hover:shadow-sm transition-all";
export const NOT_ASSESSED_BUBBLE = "p-2 bg-gray-500 rounded-l-xl rounded-r-xl mr-2 mt-2 text-white shadow-lg hover:shadow-sm transition-all";
export const ERROR_BUBBLE = "p-2 bg-orange-500 rounded-l-xl rounded-r-xl mr-2 mt-2 text-white shadow-lg hover:shadow-sm transition-all";

export const ITEM_PERFECT_INLINED = { display: 'flex', alignItems: 'center', flexWrap: 'wrap' };
export const MODAL_STYLE = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    borderRadius: 5,
    boxShadow: 24,
};
